import { Injectable, OnInit } from '@angular/core';
import { CommonField } from '../models/common-field.model';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../services/login.service';
import { Router } from '@angular/router';
import { CryptoService } from '../services/Crypto.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as moment from "moment";
import { SharedFunction } from '../pages/shared/shared-function';
import { Module } from '../enums/module.enum';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as momentTimezone from 'moment-timezone';
import { PlanUpgradeComponent } from './plan-upgrade/plan-upgrade.component';
import { MatDialog } from '@angular/material/dialog';
import { ProfilesTypes } from '../enums/profiles.enum';
import { PageDataModel } from '../models/common.model';

@Injectable({
  providedIn: 'root',
})
export class DefaultValuesService {
  commonModel: CommonField = new CommonField();
  public newSubject = new Subject<string>();
  private photoChange = new Subject<string>();
  photoChanged = this.photoChange.asObservable();
  sendPercentageCount = new Subject<number>();
  receivePercentageCount = this.sendPercentageCount.asObservable();
  customFieldsCheckParentComp = new BehaviorSubject<boolean>(false);
  customFieldsCheckParentCompObs = this.customFieldsCheckParentComp.asObservable();
  TenantId: string = '';
  schoolID: number;
  academicYear: number;
  sessionCalendarStartDate: string;
  sessionCalendarEndDate: string;
  markingPeriodStartDate: string;
  permissionListKeyName: string;
  public sendAllSchoolFlagSubject = new Subject<boolean>();
  public sendIncludeFlagSubject = new Subject<boolean>();
  public setReportCompoentTitle = new Subject<String>()
  setCommunicationPageTitle = new BehaviorSubject<string>('inbox');
  communicationPageTitle$ = this.setCommunicationPageTitle.asObservable();
  public superAdminData = new Subject();
  profiles = ProfilesTypes;

  constructor(
    public translateService: TranslateService,
    private cryptoService: CryptoService,
    private commonFunction: SharedFunction,
    private router: Router,
    private snackbar: MatSnackBar,
    private dialog: MatDialog
  ) {
  }

  // For staging server deploy
  setDefaultTenant() {
    return new Promise((resolve, reject) => {
      const url = window.location.href;
      if (url.includes('localhost')) {
        sessionStorage.setItem('tenant', JSON.stringify('110'));
        // "opensisv2_ef6"
      } else {
        let startIndex = url.indexOf('//');
        let endIndex = url.indexOf('.');
        let tenantName = url.substr(startIndex + 2, endIndex - (startIndex + 2));

        sessionStorage.setItem('tenant', JSON.stringify(tenantName));
      }
      resolve('');
    })
  }

  // For ML server deploy
  // setDefaultTenant() {
  //   return new Promise((resolve, reject)=>{
  //   let tenant = '';
  //     sessionStorage.setItem('tenant', JSON.stringify('o30'));
  // //     tenant = 'o30';
  // // this.commonModel._tenantName = tenant;
  //   resolve('');
  //   })
  // }

  setPlanId(planId: string) {
    sessionStorage.setItem("planId", JSON.stringify(planId));
  }


  getDefaultTenant() {
    return JSON.parse(sessionStorage.getItem('tenant'));
  }

  getUserName() {
    let user = JSON.parse(sessionStorage.getItem('user'));
    this.commonModel._userName = user ? user : "";
    return this.commonModel._userName;
  }
  getEmailId() {
    let email = JSON.parse(sessionStorage.getItem('email'));
    return email;
  }
  getUserGuidId() {
    return JSON.parse(sessionStorage.getItem('UserGuidId'));
  }

  setOnlinePaymentService(onlinePaymentService){
    sessionStorage.setItem("onlinePaymentService", JSON.stringify(onlinePaymentService));
  }
  getOnlinePaymentService(){
    return JSON.parse(sessionStorage.getItem("onlinePaymentService"));
  }

  setTempExternalPaymentInfo(externalPaymentInfo){
    sessionStorage.setItem("tempExternalPaymentInfo", JSON.stringify(externalPaymentInfo));
  }
  getTempExternalPaymentInfo(){
    return JSON.parse(sessionStorage.getItem("tempExternalPaymentInfo"));
  }

  setTempExternalSubscriptionInfo(externalSubscriptionInfo){
    sessionStorage.setItem("tempExternalSubscriptionInfo", JSON.stringify(externalSubscriptionInfo));
  }

  getTempExternalSubscriptionInfo(){
    return JSON.parse(sessionStorage.getItem("tempExternalSubscriptionInfo"));
  }

  setToken(token: string) {
    return new Promise((resolve, reject) => {
      sessionStorage.setItem('token', JSON.stringify(token));
      resolve('');
    })
  }

  getToken() {
    this.commonModel._token = JSON.parse(sessionStorage.getItem('token'));
    return JSON.parse(sessionStorage.getItem('token'));
  }

  setTenantID() {
    if (this.TenantId?.trim().length > 0) {
      this.TenantId = JSON.parse(sessionStorage.getItem('tenantId'));
    }
  }
  getTenantID() {
    if(sessionStorage.tenantData){
      return JSON.parse(sessionStorage.tenantData).tenantId;
    } else {
      return JSON.parse(sessionStorage.getItem('tenantId'));
    }
  }

  setSchoolID(schoolId?: string, setScholIdForStudent?: boolean) {
    if (schoolId && !setScholIdForStudent) {
      sessionStorage.setItem("selectedSchoolId", JSON.stringify(schoolId)); //JSON.Stringify
      this.schoolID = JSON.parse(sessionStorage.getItem("selectedSchoolId"));
      return;
    }
    if (setScholIdForStudent) {
      this.schoolID = +schoolId;
      return;
    }
    if (this.schoolID === null || typeof (schoolId) === "undefined") {
      this.schoolID = JSON.parse(sessionStorage.getItem("selectedSchoolId"));
    }
  }

  setFullAcademicYear(fulltAcademicYear: string) {
    sessionStorage.setItem("fullAcademicYear", JSON.stringify(fulltAcademicYear));
  }

  getFullAcademicYear() {
    return JSON.parse(sessionStorage.getItem("fullAcademicYear"));
  }

  setCourseSectionName(selectedCourseSectionName: string) {
    sessionStorage.setItem("selectedCourseSectionName", JSON.stringify(selectedCourseSectionName));
  }

  getCourseSectionName() {
    return JSON.parse(sessionStorage.getItem("selectedCourseSectionName"));
  }

  setAcademicYear(academicYear?, setScholIdForStudent?: boolean) {
    if (setScholIdForStudent) {
      this.academicYear = academicYear;
    } else {
      sessionStorage.setItem("academicyear", JSON.stringify(academicYear))
    }
  }

  getAcademicYear() {
    if (this.academicYear) {
      return this.academicYear;
    } else {
      return JSON.parse(sessionStorage.getItem("academicyear"));
    }
  }

  clearAcademicYear() {
    sessionStorage.removeItem("academicyear");
    this.academicYear = undefined;
  }

  setSessionCalendarStartDate(startDate) {
    if(startDate){
      sessionStorage.setItem("sessionCalendarStartDate", JSON.stringify(startDate))
    }
  }

  getSessionCalendarStartDate(){
    return JSON.parse(sessionStorage.getItem("sessionCalendarStartDate")); 
  }

  setSessionCalendarEndDate(endDate) {
    if(endDate){
      this.sessionCalendarEndDate = endDate;
      sessionStorage.setItem("sessionCalendarEndDate", JSON.stringify(endDate))
    }
  }

  getSessionCalendarEndDate(){
    return JSON.parse(sessionStorage.getItem("sessionCalendarEndDate")); 
  }

  getSchoolID() {
    if (!this.schoolID) {
      this.setSchoolID();
    }
    return this.schoolID;
  }

  // setFinYear(finYear?: string) {
  //   this.commonModel.finYear = sessionStorage.getItem('FinancialYear');
  // }

  // getFinYear() {
  //   this.commonModel.finYear = sessionStorage.getItem('FinancialYear');
  //   return this.commonModel.finYear;
  // }

  getAllMandatoryVariable(obj: any) {
    obj._tenantName = this.getDefaultTenant();
    obj._userName = this.getUserName();
    obj._token = this.getToken();
    obj._academicYear = this.getAcademicYear();
    obj.tenantId = this.getTenantID();
    obj.schoolId = this.getSchoolID();
    return obj;
  }

  getTenent() {
    return JSON.parse(sessionStorage.getItem('tenant'));
  }

  getuserMembershipID() {
    return JSON.parse(sessionStorage.getItem('userMembershipID'));
  }

  getuserMembershipName() {
    return JSON.parse(sessionStorage.getItem('membershipName'));
  }


  getUserMembershipType() {
    return JSON.parse(sessionStorage.getItem('membershipType'));
  }

  setUserMembershipType(membershipType?: string) {

    if (membershipType) {
      sessionStorage.setItem("membershipType", JSON.stringify(membershipType));
    }
  }

  translateKey(key) {
    let trnaslateKey;
    this.translateService.get(key).subscribe((res: string) => {
      trnaslateKey = res;
    });
    return trnaslateKey;
  }

  setPageSize(data: number) {
    sessionStorage.setItem('pageSize', JSON.stringify(data));
  }

  getPageSize(): number {
    return JSON.parse(sessionStorage.getItem('pageSize'));
  }

  setMarkingPeriodStartDate(mpStartDate?: string) {
    sessionStorage.setItem("markingPeriodStartDate", JSON.stringify(mpStartDate));
  }

  getMarkingPeriodStartDate() {
    return JSON.parse(sessionStorage.getItem("markingPeriodStartDate"));
  }

  setMarkingPeriodEndDate(mpEndDate?: string) {
    sessionStorage.setItem("markingPeriodEndDate", JSON.stringify(mpEndDate));
  }

  getMarkingPeriodEndDate() {
    return JSON.parse(sessionStorage.getItem("markingPeriodEndDate"));
  }

  createPermissionKeyName() {
    let membershipId = this.getuserMembershipID();
    this.permissionListKeyName = `permissions${membershipId}`;
    return membershipId;
  }

  decryptJson(value){
    return this.cryptoService.jsonDecrypt(value);
  }

  encryptJson(value){
    return this.cryptoService.jsonEncrypt(value);
  }

  setPermissionList(permissionList) {
    let membershipId = this.createPermissionKeyName();
    localStorage.setItem(`permissions${membershipId}`, this.cryptoService.dataEncrypt(JSON.stringify(permissionList)))
  }

  getPermissionList() {
    this.createPermissionKeyName();
    if (localStorage.getItem(this.permissionListKeyName)) {
      let permissionList = JSON.parse(this.cryptoService.dataDecrypt(localStorage.getItem(this.permissionListKeyName)));
      return permissionList;
    }
    return null;
  }

  setMarkingPeriodId(id: string) {
    sessionStorage.setItem("markingPeriodId", JSON.stringify(id));
  }

  setMarkingPeriodTitle(title: string) {
    sessionStorage.setItem("markingPeriodTitle", JSON.stringify(title));
  }

  getMarkingPeriodTitle() {
    return JSON.parse(sessionStorage.getItem("markingPeriodTitle"));
  }

  getMarkingPeriodId() {
    return JSON.parse(sessionStorage.getItem("markingPeriodId"));
  }

  //new changes for get------------------------------------------------
  getUserId() {
    return JSON.parse(sessionStorage.getItem('userId'));
  }
  getUserMasterId() {
    return JSON.parse(sessionStorage.getItem('userMasterId'));
  }
  getHttpError() {
    return JSON.parse(sessionStorage.getItem("httpError"));
  }
  getTenantName() {
    return JSON.parse(sessionStorage.getItem('tenant'));
  }
  getuserPhoto() {
    return JSON.parse(sessionStorage.getItem('userPhoto'));
  }
  getLanguage() {
    return JSON.parse(sessionStorage.getItem("language"));
  }
  getBuildVersion() {
    return JSON.parse(sessionStorage.getItem('buildVersion'));
  }
  getCourseSectionForAttendance() {
    return JSON.parse(sessionStorage.getItem("courseSectionForAttendance"));
  }
  getFullYearStartDate() {
    return JSON.parse(sessionStorage.getItem("schoolYearStartDate"));
  }
  getFullYearEndDate() {
    return JSON.parse(sessionStorage.getItem("schoolYearEndDate"));
  }
  getSchoolOpened() {
    return JSON.parse(sessionStorage.getItem('schoolOpened'));
  }
  getSchoolClosed() {
    return JSON.parse(sessionStorage.getItem('schoolClosed'));
  }
  getFullUserName() {
    return JSON.parse(sessionStorage.getItem('fullUserName'));
  }
  getSourceName() {
    return JSON.parse(sessionStorage.getItem('sourceName'));
  }
  getIsForImpersonate() {
    return JSON.parse(sessionStorage.getItem('isForImpersonate'));
  }
  getTempACHPaymentData() {
    return JSON.parse(sessionStorage.getItem('tempACHPaymentData'));
  }
  getSelectedCourseSection() {
    return JSON.parse(sessionStorage.getItem('selectedCourseSection'));
  }
  //new changes for set---------------------------------
  setFullUserName(fullUserName: string) {
    sessionStorage.setItem("fullUserName", JSON.stringify(fullUserName));
  }
  setSourceName(sourceName: string) {
    sessionStorage.setItem("sourceName", JSON.stringify(sourceName));
  }
  setIsForImpersonate(isForImpersonate: boolean) {
    sessionStorage.setItem("isForImpersonate", JSON.stringify(isForImpersonate));
  }
  setTempACHPaymentData(tempACHPaymentData: any) {
    sessionStorage.setItem("tempACHPaymentData", JSON.stringify(tempACHPaymentData));
  }
  setErrorMessage(errorMessage: string) {
    sessionStorage.setItem("httpError", JSON.stringify(errorMessage));
  }
  setSchoolOpened(schoolOpened: string) {
    sessionStorage.setItem("schoolOpened", JSON.stringify(schoolOpened));
  }
  setSchoolClosed(schoolClosed: string) {
    sessionStorage.setItem("schoolClosed", JSON.stringify(schoolClosed));
  }
  setFullYearStartDate(fullYearStartDate: string) {
    sessionStorage.setItem("schoolYearStartDate", JSON.stringify(fullYearStartDate));
  }
  setFullYearEndDate(fullYearEndDate: string) {
    sessionStorage.setItem("schoolYearEndDate", JSON.stringify(fullYearEndDate));
  }
  setTenantIdVal(tenantId: string) { //find out the uses
    sessionStorage.setItem("tenantId", JSON.stringify(tenantId));
  }
  setEmailId(email: string) {
    sessionStorage.setItem("email", JSON.stringify(email));
  }
  setUserGuidId(email: string) {
    sessionStorage.setItem("UserGuidId", JSON.stringify(email));
  }
  setUserName(user: string) {
    sessionStorage.setItem("user", JSON.stringify(user));
  }
  setUserId(userId: string) {
    sessionStorage.setItem("userId", JSON.stringify(userId));
  }
  setUserPhoto(userPhoto: string) {
    sessionStorage.setItem("userPhoto", JSON.stringify(userPhoto));
  }
  setUserMembershipID(userMembershipID: string) {
    sessionStorage.setItem("userMembershipID", JSON.stringify(userMembershipID));
  }
  setuserMembershipName(membershipName: string) {
    sessionStorage.setItem("membershipName", JSON.stringify(membershipName));
  }
  setLanguage(language: string) {
    sessionStorage.setItem("language", JSON.stringify(language));
  }
  setSelectedCourseSection(selectedCourseSection: any) {
    sessionStorage.setItem("selectedCourseSection", JSON.stringify(selectedCourseSection));
  }
  setUserMasterId(userMasterId: number) {
    sessionStorage.setItem("userMasterId", JSON.stringify(userMasterId));
  }
  //new changes foe localStorage get
  getPageData(): PageDataModel {
    return JSON.parse(localStorage.getItem("pageData"));
  }
  getCourseSectionId() {
    return JSON.parse(localStorage.getItem('courseSectionId'));
  }
  getCollapseValue() {
    return JSON.parse(localStorage.getItem("collapseValue"));
  }
  getSchoolCount() {
    return JSON.parse(localStorage.getItem("schoolCount"));
  }
  //new changes foe localStorage set

  setPageData(pageData: PageDataModel) {
    localStorage.setItem("pageData", JSON.stringify(pageData));
  }
  setCollapseValue(collapseValue: boolean) {
    localStorage.setItem("collapseValue", JSON.stringify(collapseValue));
  }
  setCourseSectionId(courseSectionId: string) {
    localStorage.setItem("courseSectionId", JSON.parse(courseSectionId));
  }
  setSchoolCount(schoolCount: any) {
    localStorage.setItem("schoolCount", JSON.stringify(schoolCount));
  }
  setTenantData(data) {
    sessionStorage.setItem('tenantData', JSON.stringify(data));
  }
  getTenantData() {
    return JSON.parse(sessionStorage.getItem('tenantData'));
  }
  setTenantAddonData(data) {
    sessionStorage.setItem('tenantAddonData', JSON.stringify(data));
  }
  getTenantAddonData() {
    return JSON.parse(sessionStorage.getItem('tenantAddonData'));
  }

  isEnterPriseClient() {
    let tenantInfo = this.getTenantData();
    let zohoPlanId = tenantInfo.zohoPlanId;
    
    return zohoPlanId.includes('ent-');
  }

  isModuleAccessible(key: string, value: number, childPermissionValue?: string) {
    let permissions = this.getPermissionList().permissionList;
    let finalPermissionCategoryNameList: any;
    let finalPermissionSubCategoryNameList: any;
    // Fetch permission group
    let finalList = permissions.filter(function (item:any) {
      return item.permissionGroup[key] === value;
      
    });

    if (!!childPermissionValue) {
      finalPermissionCategoryNameList = finalList[0]?.permissionGroup?.permissionCategory?.filter((item: any) => (item.permissionCategoryName.toLowerCase().trim()) === (childPermissionValue.toLowerCase().trim()))
      if (finalPermissionCategoryNameList.length > 0) {
        return true;
      }
      else{
        let permission = [];
        finalPermissionSubCategoryNameList = finalList[0]?.permissionGroup?.permissionCategory?.forEach((item: any)=>{
          permission.push(item?.permissionSubcategory.filter((subItem: any) => subItem.permissionSubcategoryName === childPermissionValue)[0]);
        });
        permission = permission.filter(p => p !== undefined);
        if (permission.length > 0) {
          return true;
        }
        return false;
      }
    }
    else{
      if(finalList.length > 0){
        if(finalList[0].permissionGroup.active){
          if(finalList[0].permissionGroup.rolePermission[0].canView){
            return true;
          }
        }
      }
      return false;
    }
  }

  setOfflinePaymentDetail(data) {
    sessionStorage.setItem('setOfflinePaymentDetail', JSON.stringify(data));
  }
  getOfflinePaymentDetail() {
    return JSON.parse(sessionStorage.getItem('setOfflinePaymentDetail'));
  }
  setSubsctionInfoBeforePayment(data) {
    sessionStorage.setItem('setSubscriptionDetails', JSON.stringify(data));
  }
  getSubsctionInfoBeforePayment() {
    return JSON.parse(sessionStorage.getItem('setSubscriptionDetails'));
  }

  //useing of RxJs
  sendName(data) {
    this.newSubject.next(data);
  }
  sendAllSchoolFlag(data) {
    this.sendAllSchoolFlagSubject.next(data);
  }
  sendIncludeInactiveFlag(data) {
    this.sendIncludeFlagSubject.next(data);
  }
  sendPhoto(data: string) {
    this.photoChange.next(data);
  }

  setStudentId(id: string) {
    sessionStorage.setItem("studentId", JSON.stringify(id));
  }

  getStudentId() {
    return JSON.parse(sessionStorage.getItem('studentId'));
  }

  setStudentGuid(id: string) {
    sessionStorage.setItem("studentGuid", JSON.stringify(id));
  }

  getStudentGuid() {
    return JSON.parse(sessionStorage.getItem('studentGuid'));
  }

  checkAcademicYear() {
    return moment(this.commonFunction.formatDateSaveWithoutTime(new Date())).isSameOrBefore(this.getFullYearEndDate());
  }

  setSchoolName(schoolName: any) {
    sessionStorage.setItem("schoolName", JSON.stringify(schoolName));
  }
  getSchoolName() {
    return JSON.parse(sessionStorage.getItem("schoolName"));
  }

  setPageSetupCount(pageSetupCount: any) {
    sessionStorage.setItem("pageSetupCount", JSON.stringify(pageSetupCount));
  }
  getPageSetupCount() {
    return JSON.parse(sessionStorage.getItem("pageSetupCount"));
  }
  getSchoolDateFormat() {
    return JSON.parse(sessionStorage.getItem("schoolDateFormat"));
  }

  clearSchoolDateFormat() {
    return sessionStorage.removeItem("schoolDateFormat");
  }

  clearPageSetupCount() {
    return sessionStorage.removeItem("pageSetupCount");
  }

  setSchoolSetupProgress(schoolSetupProgress: boolean) {
    sessionStorage.setItem("schoolSetupProgress", JSON.stringify(schoolSetupProgress));
  }
  getSchoolSetupProgress() {
    return JSON.parse(sessionStorage.getItem("schoolSetupProgress"));
  }
  clearSchoolSetupProgress() {
    sessionStorage.removeItem("schoolSetupProgress");
  }

  setStudentCount(studentCount: number) {
    sessionStorage.setItem("studentCount", JSON.stringify(studentCount));
  }
  getStudentCount() {
    return JSON.parse(sessionStorage.getItem("studentCount"));
  }
  clearStudentCount() {
    sessionStorage.removeItem("studentCount");
  }

  setStaffCount(staffCount: number) {
    sessionStorage.setItem("staffCount", JSON.stringify(staffCount));
  }
  getStaffCount() {
    return JSON.parse(sessionStorage.getItem("staffCount"));
  }
  clearStaffCount() {
    sessionStorage.removeItem("staffCount");
  }

  setIsSetupGuard(isSetupGuard: boolean) {
    sessionStorage.setItem("isSetupGuard", JSON.stringify(isSetupGuard));
  }
  getIsSetupGuard() {
    return JSON.parse(sessionStorage.getItem("isSetupGuard"));
  }

  setSchoolDateFormat(schoolDateFormat: any) {
    sessionStorage.setItem("schoolDateFormat", JSON.stringify(schoolDateFormat));
    new SharedFunction().setUserTimeFormat();
  }

  setSchoolCurrency(schoolCurrency: any) {
    sessionStorage.setItem("schoolCurrency", JSON.stringify(schoolCurrency));
  }  
  
  getSchoolCurrency() {
    return JSON.parse(sessionStorage.getItem("schoolCurrency"));
  }  

  checkSubscriptionExpiredOrNot() {    
    if (this.getTenantData().zohoPlanId === 'core01') {
      return false;
    } else if (this.getTenantData().zohoPlanStatus === 'trial_expired') {
      return true;
    } else if (this.getTenantData().zohoPlanStatus === 'expired') {
      return true;
    } else if (this.getTenantData().zohoPlanStatus === 'cancelled') {
      return true;
    } else if (this.getTenantData().zohoPlanStatus === 'unpaid') {
      return true;
    }
  }

  checkAndRestrictAddFunction(module: Module) {
    let status = true;
    if (this.getTenantData().zohoPlanId !== null) {
      if (module === Module.SCHOOL) {
        if (this.getTenantData().zohoPlanId === 'core01' || this.getTenantData().zohoPlanId === 'corestartup-yearly' || this.getTenantData().zohoPlanId === 'corestartup-monthly' || (this.isEnterPriseClient() === false && this.getTenantData().zohoPlanStatus === 'trial')) {
          this.snackbar.open('The limit for adding a school was exceeded. Please subscribe.', '', {
            duration: 10000
          });
          // this.router.navigate(['/school', 'my-subscription', 'subscription-plan']);
          this.checkProfileAndRedirectToDashboard();
          this.dialog.open(PlanUpgradeComponent, {
            width: '500px',
            disableClose: true
          });
          status = false;
        }
      } else if (module === Module.STUDENT) {
        if (this.getTenantData().zohoPlanId === 'core01' || this.getTenantData().zohoPlanId === 'corestartup-yearly' || this.getTenantData().zohoPlanId === 'corestartup-monthly' || (this.isEnterPriseClient() === false && this.getTenantData().zohoPlanStatus === 'trial')) {
          if (this.getStudentCount() >= this.getTenantData().maxFreeStudentSignup) {
            this.snackbar.open('The limit for adding a student was exceeded. Please subscribe.', '', {
              duration: 10000
            });
            // this.router.navigate(['/school', 'my-subscription', 'subscription-plan']);
            this.checkProfileAndRedirectToDashboard();
            this.dialog.open(PlanUpgradeComponent, {
              width: '500px',
              disableClose: true
            });
            status = false;
          }
        }
      } else if (module === Module.STAFF) {
        if (this.getTenantData().zohoPlanId === 'core01' || this.getTenantData().zohoPlanId === 'corestartup-yearly' || this.getTenantData().zohoPlanId === 'corestartup-monthly' || (this.isEnterPriseClient() === false && this.getTenantData().zohoPlanStatus === 'trial')) {
          if (this.getStaffCount() >= 5) {
            this.snackbar.open('The limit for adding a staff was exceeded. Please subscribe.', '', {
              duration: 10000
            });
            // this.router.navigate(['/school', 'my-subscription', 'subscription-plan']);
            this.checkProfileAndRedirectToDashboard();
            this.dialog.open(PlanUpgradeComponent, {
              width: '500px',
              disableClose: true
            });
            status = false;
          }
        } else {
          if (this.getTenantData().quantity && (this.getStaffCount() >= this.getTenantData().quantity)) {
            this.snackbar.open('The limit for adding a staff was exceeded. Please subscribe.', '', {
              duration: 10000
            });
            // this.router.navigate(['/school', 'my-subscription', 'subscription-plan']);
            this.checkProfileAndRedirectToDashboard();
            this.dialog.open(PlanUpgradeComponent, {
              width: '500px',
              disableClose: true
            });
            status = false;
          }
        }
      }
    }
    return status;
  }

  checkExpiryDate() {
    const browserTime = moment.utc(new Date());
    const zohoExpiryTime = moment.utc(this.getTenantData().planExpiryDate);;
    return Math.ceil((zohoExpiryTime.diff(browserTime, 'hours', true) + 4) / 24);
  }

  checkCoreStartUpPlan() {
    if (this.getTenantData().zohoPlanId === 'core01') {
      return false;
    }
    return true;
  }

  setImpersonateButton(impersonateButton: boolean) {
    sessionStorage.setItem("impersonateButton", JSON.stringify(impersonateButton));
  }
  getImpersonateButton() {
    return JSON.parse(sessionStorage.getItem("impersonateButton"));
  }

  checkProfileAndRedirectToDashboard(enableSubscriptionRouteOnly?) {
    return new Promise((resolve, reject) => {
      if (enableSubscriptionRouteOnly) {
        this.router.navigate(['/school', 'my-subscription', 'subscription-plan']);
      } else {
        if (this.getUserMembershipType() === this.profiles.HomeroomTeacher || this.getUserMembershipType() === this.profiles.Teacher) {
          this.router.navigateByUrl("/school/teacher/dashboards").then(() => {
            resolve('')
          })
        } else if (this.getUserMembershipType() === this.profiles.Student) {
          this.router.navigateByUrl("/school/student/dashboards").then(() => {
            resolve('')
          })
        }
        else if (this.getUserMembershipType() === this.profiles.Parent) {
          this.router.navigateByUrl("/school/parent/dashboards").then(() => {
            resolve('')
          })
        }
        else {
          this.router.navigateByUrl("/school/dashboards", { state: { state: true } }).then(() => {
            resolve('')
          })
        }
      }
    })
  }

  maxLengthValidationErrMsg(requiredLength, currentLength) {
    return `${this.translateKey('theTextMayNotBeLongerThan')} ${requiredLength} ${this.translateKey('characters')}. ${this.translateKey('itIsCurrently')} ${currentLength} ${this.translateKey('charactersLong')}.`
  }

  setSSOenabled(ssoEnabled) {
    sessionStorage.setItem('ssoEnabled', JSON.parse(ssoEnabled));
  }

  getSSOenabled() {
    return JSON.parse(sessionStorage.getItem('ssoEnabled'));
  }

  setSSOenabledonLoginBtn(ssoEnabled) {
    sessionStorage.setItem('ssoEnabledLoginBtn', JSON.parse(ssoEnabled));
  }

  getSSOenabledonLoginBtn() {
    return JSON.parse(sessionStorage.getItem('ssoEnabledLoginBtn'));
  }

  setClientLMSType(LMSType) {
    sessionStorage.setItem('clientLMS', LMSType);
  }

  getClientLMSType() {
    return sessionStorage.getItem('clientLMS');
  }

  setLMSClientStatus(LMSStatus) {
    sessionStorage.setItem('LMSClientStatus', LMSStatus);
  }

  getLMSClientStatus() {
    return JSON.parse(sessionStorage.getItem('LMSClientStatus'));
  }

  replaceLMSName(strToReplace: string) {
    let presentLMSName = this.getClientLMSType();

    if (presentLMSName === null || presentLMSName === undefined) {
      presentLMSName = 'LMS';
    }

    var replaceThis = new RegExp('LMS', 'g');

    if (!!this.translateKey(strToReplace)) {
      return this.translateKey(strToReplace).replace(replaceThis, presentLMSName);
    }
    else {
      if (strToReplace != '') {
        return strToReplace;
      }
      else {
        return '';
      }
    }
  }

  getLMSIconPath() {
    let lmsicon = '';
    if (this.getClientLMSType()) {
      lmsicon = this.getClientLMSType().toLowerCase();
    }
    return `assets/img/lms-icons/lms-${lmsicon.replace(" ", "-")}-icon.png`;
  }

  setSelectedCourseSectionForStaff(courseSection: any) {
    sessionStorage.setItem('selectedCourseSectionForStaff', JSON.stringify(courseSection));
  }

  getSelectedCourseSectionForStaff() {
    return JSON.parse(sessionStorage.getItem('selectedCourseSectionForStaff'));
  }

  setStaffMoodleId(staffMoodleId) {
    sessionStorage.setItem('staffMoodleId', staffMoodleId);
  }

  getStaffMoodleId() {
    return JSON.parse(sessionStorage.getItem('staffMoodleId'));
  }

  setStudentMoodleId(studentMoodleId) {
    sessionStorage.setItem('studentMoodleId', studentMoodleId);
  }

  getStudentMoodleId() {
    return JSON.parse(sessionStorage.getItem('studentMoodleId'));
  }

  setSubscriptionUserSSOenabled(ssoEnabled) {
    sessionStorage.setItem('subscriptionSsoEnabled', JSON.parse(ssoEnabled));
  }

  getSubscriptionUserSSOenabled() {
    return JSON.parse(sessionStorage.getItem('subscriptionSsoEnabled'));
  }

  setLoggedInUserMembershipType(membershipType) {
    sessionStorage.setItem('loggedInUserMembershipType', JSON.stringify(membershipType));
  }

  getLoggedInUserMembershipType() {
    return JSON.parse(sessionStorage.getItem('loggedInUserMembershipType'));
  }

  setLoggedInUserMembershipId(membershipId) {
    sessionStorage.setItem('loggedInUserMembershipId', JSON.stringify(membershipId));
  }

  getLoggedInUserMembershipId() {
    return JSON.parse(sessionStorage.getItem('loggedInUserMembershipId'));
  }

  // To check the current date belongs to the ongoing school year, returns boolean
  checkTodayInCurrentSession() {
    return (new Date(this.getFullYearEndDate()) >= new Date() && new Date(this.getFullYearStartDate()) <= new Date());
  }
}
